import { createAction, props } from '@ngrx/store';
import { AlertDetailResponse } from '@connected/data-access/fleet-service';

import {
  CommonPropertyResponse,
  CodeResponse,
} from '@paldesk/shared-lib/data-access/palcode-service-generated';
import { SearchPayload } from '../../model/search-payload.models';

export namespace AlertsActions {
  // details
  export const LoadDetails = createAction(
    '[Alerts] LOAD_DETAILS',
    props<{ payload: string }>(),
  );

  export const LoadDetailsSuccess = createAction(
    '[Alerts] LOAD_DETAILS_SUCCESS',
    props<{
      alerts?: AlertDetailResponse[] | null;
    }>(),
  );
  export const LoadDetailsError = createAction('[Alerts] LOAD_DETAILS_ERROR');

  // codes
  export const SetSearchInput = createAction(
    '[Alerts] SET_SEARCH_INPUT',
    props<{ payload: SearchPayload }>(),
  );
  export const SearchCodes = createAction('[Alerts] SEARCH_CODES');
  export const SearchCodesSuccess = createAction(
    '[Alerts] SEARCH_CODES_SUCCESS',
    props<{ payload?: CodeResponse[] }>(),
  );
  export const SearchCodesError = createAction('[Alerts] SEARCH_CODES_ERROR');
  export const LoadProducts = createAction('[Alerts] LOAD_PRODUCTS');
  export const LoadProductsSuccess = createAction(
    '[Alerts] LOAD_PRODUCTS_SUCCESS',
    props<{ payload: CommonPropertyResponse[] }>(),
  );
  export const LoadProductsError = createAction('[Alerts] LOAD_PRODUCTS_ERROR');
  export const LoadHardwares = createAction('[Alerts] LOAD_HARDWARES');
  export const LoadHardwaresSuccess = createAction(
    '[Alerts] LOAD_HARDWARES_SUCCESS',
    props<{ payload: CommonPropertyResponse[] }>(),
  );
  export const LoadHardwaresError = createAction(
    '[Alerts] LOAD_HARDWARE_ERROR',
  );
  export const ResetFilter = createAction('[Alerts] RESET_FILTER');
  export const FilterProducts = createAction(
    '[Alerts] FILTER_PRODUCTS',
    props<{ payload: string }>(),
  );
  export const FilterHardwares = createAction(
    '[Alerts] FILTER_HARDWARES',
    props<{ payload: string }>(),
  );
  export const FilterAlertTypes = createAction(
    '[Alerts] FILTER_ALERT_TYPES',
    props<{ payload: string }>(),
  );

  export const LoadPublicCodeByID = createAction(
    '[Alerts] LOAD_PUBLIC_CODE_BY_ID',
    props<{ payload: SearchPayload }>(),
  );
  export const LoadPublicCodeByIDSuccess = createAction(
    '[Alerts] LOAD_PUBLIC_CODE_BY_ID_SUCCESS',
    props<{ payload: CodeResponse }>(),
  );
  export const LoadPublicCodeByIDError = createAction(
    '[Alerts] LOAD_PUBLIC_CODE_BY_ID_ERROR',
  );

  export const LoadCodeByID = createAction(
    '[Alerts] LOAD_CODE_BY_ID',
    props<{ payload: string }>(),
  );
  export const LoadCodeByIDSuccess = createAction(
    '[Alerts] LOAD_PUBLIC_CODE_BY_ID_SUCCESS',
    props<{ payload: CodeResponse }>(),
  );
  export const LoadCodeByIDError = createAction(
    '[Alerts] LOAD_PUBLIC_CODE_BY_ID_ERROR',
  );

  export const SetLanguage = createAction(
    '[Alerts]SET_LANGUAGE',
    props<{ payload: string }>(),
  );
}
