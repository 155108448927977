import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AlertsState } from './alerts.reducer';

export namespace AlertsSelectors {
  const selectAlerts = createFeatureSelector<AlertsState>('alerts');

  export const getDetails = createSelector(selectAlerts, (state) =>
    state.details?.sort(
      (a, b) =>
        new Date(b.created_at || 0).getTime() -
        new Date(a.created_at || 0).getTime(),
    ),
  );
  export const getActiveCount = createSelector(
    selectAlerts,
    (state) => state.activeCount,
  );
  export const getIsDetailsLoading = createSelector(
    selectAlerts,
    (state) => state.isDetailsLoading,
  );
  export const getHasDetailsFailed = createSelector(
    selectAlerts,
    (state) => state.hasDetailsFailed,
  );

  export const getSearchInput = createSelector(
    selectAlerts,
    (state) => state?.searchInput,
  );
  export const getSearchResult = createSelector(
    selectAlerts,
    (state) => state.searchResult,
  );
  export const getIsSearchResultLoading = createSelector(
    selectAlerts,
    (state) => state.isSearchResultLoading,
  );
  export const getHasSearchResultFailed = createSelector(
    selectAlerts,
    (state) => state.hasSearchResulFailed,
  );

  export const getProducts = createSelector(
    selectAlerts,
    (state) => state.products,
  );
  export const getHardwares = createSelector(
    selectAlerts,
    (state) => state.hardwares,
  );
  export const getIsFilterLoading = createSelector(
    selectAlerts,
    (state) => state.isProductsLoading && state.isHardwareLoading,
  );
  export const getHasFilterFailed = createSelector(
    selectAlerts,
    (state) => state.hasProductsFailed || state.hasHardwareFailed,
  );

  export const getSelectedHardwaresId = createSelector(
    selectAlerts,
    (state) => state.hardwaresFilter,
  );

  export const getSelectedProductsId = createSelector(
    selectAlerts,
    (state) => state.productsFilter,
  );

  export const getSelectedAlertType = createSelector(
    selectAlerts,
    (state) => state.alertTypesFilter,
  );

  export const getSelectedLanguage = createSelector(
    selectAlerts,
    (state) => state?.selectedLanguage,
  );

  export const getCode = createSelector(selectAlerts, (state) => state.code);
  export const getCodeID = createSelector(
    selectAlerts,
    (state) => state.requestedCodeID,
  );
  export const getIsCodeLoading = createSelector(
    selectAlerts,
    (state) => state.isCodeLoading,
  );
  export const getHasCodeFailed = createSelector(
    selectAlerts,
    (state) => state.hasCodeFailed,
  );

  export const getFilteredResult = createSelector(
    selectAlerts,
    getSelectedProductsId,
    getSelectedHardwaresId,
    getSelectedAlertType,
    (state, productId, hardwareId, alertType) =>
      state.searchResult
        ? state.searchResult.filter(
            (item) =>
              item &&
              (productId !== 'all'
                ? item.products?.includes(productId)
                : true) &&
              (hardwareId !== 'all'
                ? item.hardware?.includes(hardwareId)
                : true) &&
              (alertType !== 'all'
                ? item.error_type && item.error_type.includes(alertType || '')
                : true),
          )
        : [],
  );
}
